@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css);
@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css);
@import url("./cookie.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
:root {
  --main-blue: #2f4171;
  --main-orange: #1a8aff;
  --step-0: clamp(1rem, 3vw, 1.1rem);
  --step-1: clamp(1rem, 3vw, 1.7rem);
  --step-2: clamp(0.8rem, 3vw, 1rem);
  --step-3: clamp(1rem, 3vw, 1.7rem);
  --step-4: clamp(1.4rem, 3vw, 2.1rem);
  --step-5: clamp(1.8rem, 3vw, 2.5rem);
}

h1 {
  font-size: var(--step-5);
}
h2 {
  font-size: var(--step-4);
}
h3 {
  font-size: var(--step-3);
}
h4 {
  font-size: var(--step-2);
}
h5 {
  font-size: var(--step-1);
}

p {
  font-size: var(--step-0);
}
small {
  font-size: var(--step--1);
}
html {
  min-height: 100%;
  padding: 0px;
  margin: 0px;
}
body {
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
  margin: 0px !important;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
}
a {
  text-decoration: none;
  color: inherit;
}
.orange-text {
  color: var(--main-orange);
}
.orange-bg {
  background-color: var(--main-orange);
}
.blue-bg {
  background-color: var(--main-blue);
}
.blue-text {
  color: var(--main-blue);
}
.spread-button {
  background-color: var(--main-orange);
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.8rem 2rem;
  border: none;
  outline: none;
  transition: box-shadow 0.2s linear;
  font-weight: 500;
}
.spread-button:hover,
.spread-button:focus,
.spread-button:active {
  box-shadow: inset 0px 0px 0px 100px rgba(0, 0, 0, 0.3);
}
.spread-button-alt {
  background-color: transparent;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 2rem;
  outline: none;
  transition: box-shadow 0.2s linear;
  font-weight: 500;
}
.spread-button-alt2 {
  background-color: #F4F9FF;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 2rem;
  outline: none;
  transition: box-shadow 0.2s linear;
  font-weight: 500;
}
.spread-button-alt:hover,
.spread-button-alt:focus,
.spread-button-alt:active {
  box-shadow: inset 0px 0px 0px 100px rgba(0, 0, 0, 0.3);
  color: #fff;
}
.spread-button-alt2:hover,
.spread-button-alt2:focus,
.spread-button-alt2:active {
  box-shadow: inset 0px 0px 0px 100px var(--main-orange);
  color: #fff;
}
.spread-button-2F4171 {
  background-color: #2F4171;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.8rem 2rem;
  outline: none;
  transition: box-shadow 0.2s linear;
  font-weight: 500;
}
.spread-button-2F4171:hover,
.spread-button-2F4171:focus,
.spread-button-2F4171:active {
  box-shadow: inset 0px 0px 0px 100px rgba(0, 0, 0, 0.3);
  color: #fff;
}
.full {
  width: 100%;
}

.course img {
  max-width: 200px;
}

::-webkit-scrollbar {
  display: inline-flex;
  width: 0.45vw;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, #1a8aff);
  border-radius: 10px;
}

/* Position the modal relative to the chat container */
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 0px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 474px;
}
.chatbot-icon {
  position: fixed;
  bottom: 20px;
  right: 0px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 474px;
}
/* Chat window styling */
.chat-window {
  width: 100%;
  color: #282828;
  display: flex;
  flex-direction: column;
  height: 82vh;
  overflow-y: auto;
  position: relative; /* Ensure modal is contained */
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 8px 4px rgba(26, 138, 255, 0.1);
}

.ai-logo {
  margin: 0 auto 0;
}

.ai-customer-rep {
  color: #282828;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 32.4px */
  margin-top: 20px;
}

/* Chat header styling */
.chat-header {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f9ff;
  height: 68px;
  border-radius: inherit;
}

.pba-header {
  background: linear-gradient(90deg, #1a8aff 17.3%, #2f4171 66.8%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 169.231% */
  text-align: center;
}

/* Chat intro styling */
.chat-intro {
  padding: 10px;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chat-intro-message {
  color: #282828;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 25.2px */
  max-width: 396px;
  margin: 0 auto;
}

.action-button {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  display: inline-flex;
  padding: 51px 16px 67px 16px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  color: #00093a;
}

.prefilled-questions {
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 394px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  background: #f4f9ff;
  margin: 24px auto 0;
}

.prefilled-que {
  width: 100%;
  display: flex;
  padding: 15px 4px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: #fff;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 21.6px */
}
/* Chat content and message styling */
.chat-content {
  display: flex;
  flex-direction: column;
  max-height: 70%;
  width: 100%;
  position: relative;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  padding-bottom: 100px;
}

.message {
  padding: 8px 12px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: transparent !important;
}

.message.user {
  align-self: flex-end;
  width: 100%;
  max-width: 80%;
  border-radius: 4px;
  border: 1px solid #2f4171;
  background: #f4f9ff;
  display: inline-flex;
  padding: 12px 46px 12px 10px;
  color: rgba(0, 0, 0, 0.7);
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: 0.12px;
}

.message.bot {
  position: relative;
  align-self: flex-start;
  width: 100%;
  max-width: 80%;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 21.6px */
  letter-spacing: 0.12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.arrow-dwon {
  border-radius: 15px;
  border: 1px solid #92c2f4;
  background: #1a8aff;
}

.markdown {
  border-radius: 4px;
  background: #1a8aff;
  padding: 12px 6px 12px 10px;
  color: rgba(255, 255, 255, 0.8);
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 21.6px */
  letter-spacing: 0.12px;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 21.6px */
  letter-spacing: 0.12px;
}

.markdown p {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 180%; /* 21.6px */
  letter-spacing: 0.12px;
}

.markdown h1 {
  font-size: 24px !important;
  font-weight: 600;
}
.markdown h2 {
  font-size: 20px !important;
  font-weight: 500;
}
.markdown h3 {
  font-size: 18px !important;
  font-weight: 500;
}
.markdown ul {
  margin-left: 30px !important;
  list-style: disc;
  margin-top: 10px;
  margin-bottom: 10px;
}

.markdown ol {
  margin-left: 30px;
  list-style: decimal;
  margin-top: 10px;
  margin-bottom: 10px;
}

.feedback-msg {
  max-width: 80%;
  border-radius: 4px;
  background: #1a8aff;
  padding: 12px 6px 12px 10px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
}

.like-dislike {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.message.bot table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.message.bot table th,
.message.bot table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.message.bot table th {
  background-color: #282828;
  color: white;
}

.message.bot table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.message.bot table tr:hover {
  background-color: #f1f1f1;
}

.message.bot table th {
  padding-top: 12px;
  padding-bottom: 12px;
}

/* Input area styling */
.input-area {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: auto !important;
  background: #f4f9ff;
  padding: 10px 20px;
  border-radius: inherit;
}

.input-area textarea {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 8px;
  resize: none;
  color: #282828;
  padding: 12px 10px 11px 12px;
  width: 100%;
  outline: 0;
}

.input-area button {
  position: absolute;
  z-index: 3;
  /* top: 10; */
  right: 30px;
  border: none;
  background: none;
  margin-left: 10px;
  cursor: pointer;
}

.input-area button img {
  width: 24px;
  height: 24px;
}

/* Loading animation styling */
.loading {
  display: flex;
  align-items: center;
  background: inherit;
  padding: 0 12px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #1a8aff;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

/* Bounce animation for loading dots */
@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

/* Modal styling */
.modala-overlay {
  position: absolute; /* Position the overlay absolutely within the chat window */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001; /* Ensure it appears above other elements */
  border-radius: 10px;
}

.modala {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.modala h2 {
  margin-bottom: 10px;
  font-size: 18px !important;
}

.modala p {
  margin-bottom: 20px;
  font-size: 16px !important;
}

.modala-actions {
  display: flex;
  justify-content: space-around;
}

.confirm-btn,
.cancel-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-btn {
  background-color: #1a8aff;
  color: white;
}

.cancel-btn {
  background-color: #f1f1f1;
  color: black;
}

.MuiInputBase-root {
  padding: 0;
  margin-top: 0;
  border: 1px solid #00000033;
  background: white;
  height: 40px;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9px !important;
  font-size: 12px !important;
}

/* Adjust chat container position for larger screens */
@media only screen and (min-width: 768px) {
  .chatbot-container {
    right: 20px;
  }
  .chatbot-icon {
    right: 20px;
  }
  .action-button {
    padding: 51px 20px 67px 20px;
  }
}
.radio-card {
  border-radius: 20px;
  background: #f9f9f9;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
@media only screen and (max-width: 768px){
  .spread-button,
  .spread-button-alt,
  .spread-button-alt2,
  .spread-button-2F4171{
    font-size: 11px;
    padding: 10px 15px;
    border-radius: 5px;
  }
}